import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';

import {
  Form,
  Button,
  IconSpinner,
  FieldTextInput,
  FieldCheckbox,
  FieldRadioButton,
} from '../../components';

import css from './InviteBuyerForm.module.css';

class InviteBuyerFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
  }

  componentWillUnmount() {}

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            pristine,
            rootClassName,
            updateInProgress,
            inviteBuyerError,
            inviteHasAlreadySentError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'InviteBuyerForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'InviteBuyerForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'InviteBuyerForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'InviteBuyerForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'InviteBuyerForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'InviteBuyerForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // Email
          const emailLabel = intl.formatMessage({
            id: 'InviteBuyerForm.emailLabel',
          });
          const emailPlaceholder = intl.formatMessage({
            id: 'InviteBuyerForm.emailPlaceholder',
          });
          const emailRequiredMessage = intl.formatMessage({
            id: 'InviteBuyerForm.emailRequired',
          });
          const emailRequired = validators.businesEmailValid(emailRequiredMessage);

          const submitError = inviteBuyerError ? (
            <div className={css.error}>
              <FormattedMessage id="InviteBuyerForm.error" />
            </div>
          ) : null;

          const inviteAlreadySent = inviteHasAlreadySentError ? (
            <div className={css.error}>
              Buyer has already been invited to Platter.<br></br>We will send them a reminder.
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                e.preventDefault();
                handleSubmit(this.submittedValues);
              }}
            >
              <h1 className={css.sectionTitle}>Invite a buyer</h1>
              <p>
                Invite your new buyer to join Platter and start ordering with our simple signup
                form.
              </p>

              <div className={css.nameContainer}>
                <FieldTextInput
                  className={css.firstName}
                  type="text"
                  id="firstName"
                  name="firstName"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  className={css.lastName}
                  type="text"
                  id="lastName"
                  name="lastName"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                />
              </div>

              <FieldTextInput
                inputRootClass={css.textarea}
                type="texta"
                id="InviteBuyerForm"
                name="email"
                placeholder="Buyer business email"
                data-cy="inputInviteBuyer"
                validate={emailRequired}
              />
              <div className={css.hiddenFields}>
                <FieldTextInput
                  inputRootClass={css.textarea}
                  type="textarea"
                  id="inviteType"
                  name="inviteType"
                  defaultValue="supplier-sent-buyer-request-for-info"
                />
                <FieldTextInput
                  inputRootClass={css.textarea}
                  type="textarea"
                  id="userType"
                  name="userType"
                  defaultValue="buyer"
                />
              </div>

              {submitError}
              {inviteAlreadySent}

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                INVITE BUYER
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

InviteBuyerFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inviteBuyerError: null,
  inviteBuyerReady: false,
};

InviteBuyerFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inviteBuyerError: propTypes.error,
  inviteBuyerReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const InviteBuyerForm = compose(injectIntl)(InviteBuyerFormComponent);

InviteBuyerForm.displayName = 'InviteBuyerForm';

export default InviteBuyerForm;
